
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = gpf.dataStatus();

    const titleTable: Array<string> = [
      "Title",
      "Status",
      "Created",
      "#",
    ];

    // "id": 2,
    // "slug": "info-pppk",
    // "title": "Info PPPK",
    // "isActive": 1,
    // "createdAt": "2021-04-21 22:20:40"

    const dataBT = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingBTadmin").show();

      const hide = () => cash("#loadingBTadmin").hide();
      const page = dataBT.page;
      const pageSize = dataBT.pageSize;
      const search = dataBT.search;
      const url = api.adminBlogTag +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;
      // console.log("response get du ", rs);

      dataBT.data = rs;
      dataBT.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataBT.page = p;
      getData();
    };

    const nextPage = () => {
      dataBT.page = dataBT.page + 1;
      getData();
    };

    const beforePage = () => {
      dataBT.page = dataBT.page - 1;
      getData();
    };

    const lastPage = () => {
      dataBT.page = dataBT.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataBT,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
    };
  },
  data() {
    return {
      // for modal data bs
      section: "add",

      exDataBT: {
        id: 0,
        title: "",
        status: "1",
      },

      exDeleteBT: {
        id: 0,
        title: "",
      },

      listKodeKS: [],
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataBT;
      ex[f] = val;
    },
    async emptyField() {
      const ex = this.exDataBT;
      ex.title = "";
      ex.status = "1";
    },
    async addBT() {
      await this.emptyField();
      this.section = "add";
      gpf.showModal("#modal-data-bt");
    },
    async addBTApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataBT;
      const params = new URLSearchParams();
      params.append("title", ex.title);
      params.append("status", ex.status);

      const url = api.adminBlogTag;
      const hitApi = await api.hitApiPost(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-bt");
      await this.emptyField();
      await this.getData();

    },
    editBT(data: any) {
      this.section = "edit";

      const ex = this.exDataBT;
      ex.id = data.id;
      ex.title = data.title;
      ex.status = data.isActive.toString();

      gpf.showModal("#modal-data-bt");
    },
    async editBTApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataBT;
      const params = new URLSearchParams();
      params.append("title", ex.title);
      params.append("status", ex.status);

      const url = api.adminBlogTag + ex.id;
      const hitApi = await api.hitApiPut(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-bt");
      await this.emptyField();
      await this.getData();
    },
    deleteBT(data: any): void {
      const ex = this.exDeleteBT;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-bt");
    },
    async deleteBTApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exDeleteBT;

      const url = api.adminBlogTag + ex.id;
      const hitApi = await api.hitApiDel(url, hide);
      ex.id = 0;
      ex.title = "";

      await gpf.handleSds(hitApi);
      await gpf.hideModal("#delete-modal-data-bt");
      await this.getData();
    }
  },
});
